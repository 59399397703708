import './App.css';
import { BuilderComponent } from '@builder.io/react';
import PopUp from './containers/cookie-pop-up/pop-up'

function App() {
  return (
    <div>
    <BuilderComponent model="page" /> <PopUp /> 
    </div>
  );
}

export default App;
